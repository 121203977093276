import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
  })
  
  Bugsnag.getPlugin('vue')
    .installVueErrorHandler(Vue)
}

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'

Vue.use(VueToast)

import 'pixelstein-vue-app-package/src/update-checker'

import ConfigLoader from 'pixelstein-vue-app-package/src/plugins/config-loader'

import ApiErrorHandlerPlugin
  from '@pixelstein/api-error-handler-plugin/dist/ApiErrorHandlerPlugin'

const apiErrorHandlerPlugin = new ApiErrorHandlerPlugin()
Vue.use(apiErrorHandlerPlugin)

Vue.config.productionTip = true;

(async function () {
  await ConfigLoader()
  
  if (
    window?.cordova?.InAppBrowser?.open
    && window?.cordova?.platformId === 'ios'
  ) {
    window.open = (location, target) => {
      window.cordova.InAppBrowser.open(location, target,
        'location=no,toolbarposition=top,lefttoright=yes')
    }
  }
  
  window.vm = new Vue({
    router,
    // i18n,
    data: {
      user: null,
      language: 'de',
    },
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
})()

