<template>
  <form enctype="multipart/form-data" @submit="upload">
    <div class="row">
      <div class="col-xs-10 f-input-wrapper">
        <input name="file"
               type="file"
               class="form-control"
               style="padding: 0 !important;"
               :accept="accept"
               @change="changed"
        />
        <div class="form-control">{{ filename }}</div>
      </div>
      <div class="col-xs-12 col-sm-2">
        <button type="submit"
                class="bg-light-blue search-button">
          Upload
        </button>
      </div>
    </div>
  </form>
</template>

<script>

export default {
  name: "FileUpload",
  props: {
    apiAction: String,
    accept: {type: String, default: "application/zip"}
  },
  data() {
    return {
      filename: 'Durchsuchen...'
    }
  },
  methods: {
    upload(event) {
      event.preventDefault();
      let data = new FormData(event.target);
      this.fileUpload(this.apiAction, data, event.target);
    },
    changed(event) {
      if (event.target.files[0]) {
        this.filename = event.target.files[0].name
      }
    },
    fileUpload(action, data, formNode) {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'multipart/form-data');

      if (formNode) {
        formNode.classList.add('pending')
      }

      fetch(action, {
        body: data,
        method: 'POST',
        // mode: 'cors',
        //headers: myHeaders,
        credentials: 'include',
      }).then(response => {

        if (response.status !== 200) {
          throw response
        }

        return response.json();
      }).then(res => {
        if (formNode) {
          formNode.classList.remove('pending')
        }

        if (res && res.status === 'ok') {
          alert('Upload erfolgreich!\nDen import Status können Sie im Tab "Tasks" einsehen.')
        }
      }).catch(err => {
        if (formNode) {
          formNode.classList.remove('pending')
        }

        alert(err.message);

        this.$router.push('/');
      })

    },
  },
}
</script>

<style scoped>

</style>
