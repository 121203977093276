<template>
  <div v-if="image"
       style="position: fixed; width: 100vw; height: 100vh; left: 0; top: 0; z-index: 9999999; background: rgba(33,33,33,.75)">

    <pinch-image :src="image" :min-zoom="1" :max-zoom="3"></pinch-image>

    <mobile-navigation class="bg-light-blue">
      <button v-if="clipBoardAvailable"
              type="button"
              style="margin: 0 5px; width: auto; height: auto; background: transparent;"
              @click="$emit('clipboard', image)"
      >
        <i class="fas fa-clipboard  fa-2x" aria-hidden="true"></i><br>
        Kopieren
      </button>
      <button type="button"
              style="margin: 0 5px; width: auto; height: auto; background: transparent;"
              @click="$emit('hide')"
      >
        <i class="fas fa-minimize  fa-2x" aria-hidden="true"></i><br>
        Schließen
      </button>
    </mobile-navigation>
  </div>
</template>

<script>
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import PinchImage from "./PinchImage";
import MobileNavigation from "@/components/MobileNavigation";
import {Device} from '@capacitor/device';

export default {
  name: "PinchZoomOverlay",
  components: {MobileNavigation, PinchImage},
  props: {
    image: String,
  },
  watch: {
    image(newImage) {
      if (newImage) {
        document.body.classList.add('pinch-zoom-active')
        this.openInfoToast()
      } else {
        document.body.classList.remove('pinch-zoom-active')
      }
    }
  },
  data() {
    return {
      clipBoardAvailable: false
    }
  },
  created() {
    Device.getInfo()
        .then((info) => this.clipBoardAvailable = info.platform !== 'web')
        .catch(() => this.clipBoardAvailable = false)
  },
  methods: {
    openInfoToast() {
      Toastify({
        text: this.$t('pinch_zoom_info'),
        duration: 3000,
        close: false,
        gravity: "bottom", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        backgroundColor: "rgba(0,0,0,.7)",
        stopOnFocus: true, // Prevents dismissing of toast on hover
      }).showToast();

    }

  }
}
</script>

<style scoped>

</style>
