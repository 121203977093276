<template>
  <div class="multi-file-upload">
    <label v-if="label">{{ label }}
      <template v-if="required">*</template>
    </label>
    <div v-if="files.length > 0" class="file-item-wrap">
      <div v-for="file in files"
           :key="file.id"
           class="file-item"
      >
        <button type="button"
                class="remove-file-btn"
                @click="removeFile(file)"
        >
          &times;
        </button>
        <img :src="file.thumbnail" class="img-fluid" :alt="file.file.name">
        <progress v-if="file.progress > 0" max="100" :value="file.progress">{{ file.progress }}%</progress>
      </div>
    </div>
    <div v-if="files.length < maxFileCount" style="display: flex">
      <label v-for="i in inputCount" :key="'input_'+i" class="upload-label">
        <input type="file"
               :required="required && i === 1"
               multiple
               @invalid.prevent="$emit('validity-change', false)"
               @valid.prevent="$emit('validity-change', true)"
               @change="addFiles"
               :accept="allowedMimeTypes.join()">
        <i class="fa-solid fa-images" aria-hidden="true"></i>
      </label>
      <button v-if="platform === 'ios'"
              type="button"
              @click="getFromClipboard"
      >
        <i class="fas fa-paste" aria-hidden="true"></i>
      </button>
    </div>
  </div>

</template>

<script>

import Vue from 'vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

import {v1} from "uuid";
import prettyBytes from 'pretty-bytes';
import _ from 'lodash'

import {Clipboard} from "@capacitor/clipboard";
import {Device} from '@capacitor/device';

import Toastify from "toastify-js";

export default {
  name: "MultiFileUpload",
  components: {
    // CustomFormContents,
    // FormInput,
  },
  props: {
    maxFileSize: {type: Number, default: () => 0}, //in Byte
    allowedMimeTypes: {type: Array, default: () => ["image/png", "image/jpeg", "application/pdf"]},
    maxFileCount: {type: Number, default: 6},
    children: {type: Array, default: () => []},
    required: {type: Boolean, default: false},
    label: {type: String, default: ""},
    listenPasteEvents: {type: String, default: null}
  },
  data() {
    return {
      inputCount: 1,
      files: [],
      platform: 'web'
    }
  },
  computed: {
    allCompleted() {
      return !this.files.some(el => el.success === null)
    },
  },
  mounted() {
    (async () => this.platform = (await Device.getInfo()).platform || "web")()

    if (this.listenPasteEvents) {
      this.paste_node = document.querySelector(this.listenPasteEvents)
      if (this.paste_node) {
        this.paste_node.addEventListener('paste', this.onPaste)
      }
    }

  },
  beforeDestroy() {
    if (this.paste_node) {
      this.paste_node.removeEventListener('paste', this.onPaste)
    }
  },
  updated() {
    const childrenKeys = this.children.map(child => child.options.key);
    const filteredFiles = this.files.map(f => {
      let result = {id: f.id, onUploadProgressForFile: f.onUploadProgressForFile, file: f.file, size: f.size}

      childrenKeys.forEach(child => {
        result[child] = f[child]
      })

      return result
    })

    // console.log(filteredFiles)

    this.$emit('input', filteredFiles)
  },
  methods: {
    async getFromClipboard() {

      try {
        const {type, value} = await Clipboard.read()
        console.log({type, value})
        if (!!this.allowedMimeTypes.find(mime => mime === type)) {
          const response = await fetch(value);
          const blob = await response.blob();
          const f = new File([blob], "from_clipboard.png", {type: "image/png"})
          await this.addFiles({target: {files: [f]}})

        } else {
          throw new Error("Zwischenablage enthält keine Dateien")
        }
      } catch (e) {
        Toastify({
          text: e.message,
          duration: 3000,
          close: false,
          gravity: "bottom", // `top` or `bottom`
          position: "center", // `left`, `center` or `right`
          backgroundColor: "rgba(0,0,0,.7)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
        }).showToast();
      }

    },
    onPaste(event) {

      let files = []

      const items = event.clipboardData.items;
      if (items) {
        console.log({clipboardDataItems: items});

        for (const item of items) {
          // Skip content if mime is not allowed
          if (!this.allowedMimeTypes.find(mime => mime === item.type)) {
            continue
          }
          event.preventDefault();

          // Retrieve image on clipboard as blob
          const blob = item.getAsFile();

          files.push(blob)
          console.log(files)
        }

        this.addFiles({target: {files}})

      }
    },
    async addFiles({target: {files}}) {

      // call them as such; files[0].size will get you the file size of the 0th file
      for (let file of files) {

        if (this.files.length === this.maxFileCount) {
          this.$toast.open({
            type: "error",
            message: this.$t('multi_file_upload.errors.max_files_reached', {count: this.maxFileCount}),
            position: this.$config.TOAST_POSITION
          });
          break;
        }

        if (this.allowedMimeTypes.length > 0 && !this.allowedMimeTypes.find(mime => mime === file.type)) {
          this.$toast.open({
            type: "error",
            message: this.$t('multi_file_upload.errors.mime_type', {
              filename: file.name,
              type: file.type,
              allowedMimeTypes: this.allowedMimeTypes.join(', ')
            }),
            position: this.$config.TOAST_POSITION
          });

          continue;
        }

        const fileSizeSummary = this.files.map(f => f.file.size).reduce((previousValue, currentValue) => currentValue + previousValue, 0)
        if (this.maxFileSize !== 0 && file.size > ((this.maxFileSize * .9) - fileSizeSummary)) {
          this.$toast.open({
            type: "error",
            message: this.$t('multi_file_upload.errors.file_size', {
              filename: file.name,
              size: prettyBytes(file.size, {locale: this.$i18n.locale}),
              maxFilesize: prettyBytes(this.maxFileSize * .9, {locale: this.$i18n.locale}),
              availableSize: prettyBytes(this.maxFileSize * .9 - fileSizeSummary, {locale: this.$i18n.locale})
            }),
            position: this.$config.TOAST_POSITION
          });

          continue;
        }

        const imageUrl = file.type.indexOf('image/') > -1 || this.platform !== 'web'
            ? await this.createImageUrl(file)
            : '/img/file-pdf-solid.svg';


        const estimatedSize = file.type.substring('image/') > -1
            ? await this.getEstimatedSize(imageUrl)
            : ({
              width: 100,
              height: 100
            })


        this.files.push({
          file: file,
          filesize: prettyBytes(file.size, {locale: this.$i18n.locale}),
          thumbnail: imageUrl,
          size: estimatedSize,
          name: file.name,
          price: 1,
          format: "",
          id: v1(),
          progress: 0,
          onUploadProgressForFile: this.onUploadProgressForFile
        })
      }

      this.inputCount++

    },
    onUploadProgressForFile(file, progressEvent) {
      const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
      console.log("onUploadProgress", totalLength);
      if (totalLength !== null) {

        const perc = Math.round((progressEvent.loaded * 100) / totalLength)
        const idx = this.files.findIndex(el => el.id === file.id);

        this.$set(this.files[idx], 'progress', perc)

      }
    },
    mergeIntoFile(file, value) {
      _.merge(file, value)
      this.$forceUpdate()
    },
    getEstimatedSize(file) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
          resolve({width: image.width, height: image.height})
        }

        image.onerror = reject

        image.src = file
      })

    },
    createImageUrl(file) {
      return new Promise(((resolve, reject) => {

        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }

        reader.onerror = ev => reject(ev)

        reader.readAsDataURL(file)

      }))

    },
    removeFile(item) {
      const idx = this.files.findIndex(file => file.id === item.id);
      this.files.splice(idx, 1);
    },
  }
}
</script>
