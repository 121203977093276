<template>
  <div class="chat" style="height: 100%">
    <div class="container" style="position:relative; min-height: 100%" :style="`padding-bottom: ${controlHeight}px`">
      <div v-if="user.admin"
           class="user-list"
           :class="{'show-list':showUserList}"
           style="cursor: pointer"
           @click.prevent.stop="showUserList = !showUserList"
      >
        <button class="bg-light-blue">
          <i class="fa-solid fa-user" aria-hidden="true"></i>

        </button>
        <strong>Chat mit: {{ userForId(userId).username }} | {{ userForId(userId).last_name }} |
          {{ userForId(userId).company }}</strong>
        <div v-if="showUserList" class="users">
          <button class="bg-blue" @click="msgToAll = true">Info an Alle Benutzer senden</button>
          <div @click.prevent.stop>
            <form-input v-model="filterQuery" placeholder="Nach Kundennummer oder Namen filtern"/>
          </div>
          <router-link
              v-for="u in sortedUsers"
              :key="'user_'+u.id"
              :to="(!!$route.path.match(/admin/)?'/admin/babble/chat/':'/chat/')+u.id"
          >
            {{ u.username }} | {{ u.last_name }}
            <span v-if="(user.admin ? u.unread_admin_count : u.unread_user_count) > 0" class="badge"
                  style="float: right; margin-left: 10px">{{
                user.admin ? u.unread_admin_count : u.unread_user_count
              }}</span>
          </router-link>
        </div>
      </div>
      <div class="row spacer-top spacer-bottom" @click="handleBabbleClick">
        <div v-for="babble in sortedBabbles" :key="'babble_'+babble.id" class="col-xs-12">
          <babble :class="{own: isOwnMessage(babble)}">
            <div class="small text-right">
            </div>
            <div v-html="babble.body"></div>
            <div class="small text-right pt-3">
              <hr style="margin: 3px 0 !important; border-top: 0 !important;">
              {{ toRelativeTime(babble.created) }}
              <a v-if="user.admin" href="#" @click.prevent="msgToDelete = babble">
                <i class="fas fa-trash"
                   style="color: white; margin-left: 1em"
                   aria-hidden="true"></i>
              </a>
            </div>
          </babble>

        </div>
      </div>
      <div ref="end_of_chat"> </div>
      <chat-control
          :disabled="(user.admin && userId === 'admin')"
          allow-uploads

          @resize="controlHeight = $event"
          @files-changed="files = $event"
          @submit="trySendBubble"
      />
    </div>
    <modal v-if="msgToAll"
           :visible="msgToAll"
           title="Nachricht an ALLE Benutzer senden"
           :lock="false"
           positive-button="Abbrechen"
           @negative-response="msgToAll = false; notifyAllFiles=[]"
           @positive-response="msgToAll = false; notifyAllFiles=[]"
    >
      <p>Die eingegebene Nachricht wird an Alle Benutzer versendet.</p>
      <chat-control allow-uploads
                    :class="{'has-files': notifyAllFiles.length > 0}"
                    style="position: static !important; width: 100% !important; padding:0; border: 1px solid #EDEDED"
                    @files-changed="notifyAllFiles = $event"
                    @submit="tryNotifyAll"
      />
    </modal>
    <modal v-if="msgToDelete"
           :visible="!!msgToDelete"
           title="Nachricht löschen"
           :lock="deleting"
           negative-button="Abbrechen"
           @negative-response="msgToDelete = null"
           @positive-response="tryDeleteBabble"
    >
      Sind Sie sicher, dass Sie die Nachricht "{{
        msgToDelete.body.substring(0, 10)
      }}<span v-if="msgToDelete.body.length > 10">...</span>"
      löschen wollen?
    </modal>
    <pinch-zoom-overlay
        :image="pinchImage"
        @hide="pinchImage=null"
        @clipboard="addToClipboard"
    />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

import {marked} from "marked";

import dayjs from "dayjs";
import calendar from 'dayjs/plugin/calendar';
import locale_de from "dayjs/locale/de"

dayjs.extend(calendar)
dayjs.locale(locale_de)

import {Clipboard} from '@capacitor/clipboard';

import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

import Babble from "@/components/Babble";
import ChatControl from "@/components/ChatControl";
import PinchZoomOverlay from "@/components/PinchZoomOverlay";

import Modal from "@/components/Modal";
import FormInput from "pixelstein-vue-app-package/src/components/Form/FormInput";

export default {
  name: "Chat",
  components: {
    Modal, ChatControl, Babble, PinchZoomOverlay, FormInput
  },
  props: {
    userId: {type: String, default: "admin"}
  },
  watch: {
    userId() {
      this.clearBabbles();
      this.tryGetBabbles()
    }
  },
  data() {
    return {
      controlHeight: 0,
      showUserList: false,
      msgToDelete: null,
      deleting: false,
      msgToAll: false,
      files: [],
      notifyAllFiles: [],
      pinchImage: null,
      filterQuery: '',
      scrollDown: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.Api.Users.current,
      users: state => state.Api.Users.all,
      babbles: state => state.Api.Babbles.all,
    }),
    sortedBabbles() {
      // sort desc by created date
      return this.babbles.slice().sort((a, b) => a.created.localeCompare(b.created))
    },
    sortedUsers() {
      return this.filteredUsers.slice().sort((a, b) => a.username.localeCompare(b.username))
    },
    filteredUsers() {
      const regex = new RegExp(this.filterQuery, "i")
      return this.users.filter(u => !!(u.username + u.last_name).match(regex))
    }
  },
  mounted() {
    this.tryGetBabbles()

    this.pollingInterval = window.setInterval(() => {
      this.tryGetBabbles(this.scrolledToEnd())
    }, 2000);

    if (this.user.admin && this.userId === 'admin') {
      this.showUserList = true
    }

  },
  updated: function () {
    if(this.scrollDown) {
      this.$nextTick(function () {
        this.$refs.end_of_chat.scrollIntoView({behavior: "smooth", block: "center"})
        this.scrollDown = false
      })
    }
  },
  beforeDestroy() {
    if (this.pollingInterval) {
      window.clearInterval(this.pollingInterval);
    }

  },
  methods: {
    ...mapActions({
      getUsers: "Api/Users/all",
      getBabbles: "Api/Babbles/all",
      sendBabble: "Api/Babbles/send",
      deleteBabble: "Api/Babbles/delete",
      notifyUser: "Api/Users/notify",
      addFile: "Api/Files/add"
    }),
    ...mapMutations({
      addBabble: "Api/Babbles/addOrUpdate",
      clearBabbles: "Api/Babbles/reset",
    }),
    handleBabbleClick(e) {
      console.log('click', e, e.target, e.target.parentNode)

      const setUrl = (url) => {
        if (url.split('.').pop() === "pdf") {
          return
        }
        e.preventDefault();
        this.pinchImage = url
      }

      if (e.target.classList.contains('chat-img')) {
        setUrl(e.target.dataset.file || e.target.href);
        return
      }

      if (e.target.parentNode.classList.contains('chat-img')) {
        setUrl(e.target.parentNode.href);
        return
      }
    },
    toRelativeTime(dateTime) {
      return dayjs(dateTime, this.$config.API_DATE_TIME_FORMAT).calendar(null, {
        sameDay: 'HH:mm', // The same day ( Today at 2:30 AM )
        nextDay: '[Morgen] HH:mm', // The next day ( Tomorrow at 2:30 AM )
        nextWeek: 'dddd HH:mm', // The next week ( Sunday at 2:30 AM )
        lastDay: '[Gestern] HH:mm', // The day before ( Yesterday at 2:30 AM )
        lastWeek: 'dddd h:mm A', // Last week ( Last Monday at 2:30 AM )
        sameElse: 'DD.MM.YYYY HH:mm' // Everything else ( 17/10/2011 )
      })
    },
    async prepareMessageImages(files) {
      console.log({files})


      let attachmentHTML = []
      for (let fileObj of files) {
        console.log(fileObj, fileObj.size.width, fileObj.size.height)
        let size = {width: 100, height: 100}
        if (typeof fileObj === "object" && fileObj.size.width) {
          size = fileObj.size
        }

        const file = await this.addFile({
          ...fileObj, $uploadProgressCallback: (e) => {
            fileObj.onUploadProgressForFile(fileObj, e)
          }
        })

        console.log("image mime", fileObj.file.type.startsWith("image/"));

        if (fileObj.file.type.startsWith("image/")) {
          const html = `<img data-file="${this.$config.API_BASE_URL}${file.file}" class="chat-img" src="${this.$config.API_BASE_URL}${file.thumbnail}" width="${size.width}" height="${size.height}" class="img-responsive">`
          attachmentHTML.push(html)

        } else {

          const html = `
<a href="${this.$config.API_BASE_URL}${file.file}" class="chat-img" target="_blank" title="${file.filename}">
    <img src="${this.$config.API_BASE_URL}${file.thumbnail}" width="${size.width}" height="${size.height}" class="img-responsive">
</a>
`
          attachmentHTML.push(html);

        }

      }

      return attachmentHTML
    },
    tryGetBabbles(scrollToEnd = true) {
      if (this.user.admin && this.userId === 'admin') {
        this.clearBabbles();
        return
      }

      const babblesCount = this.babbles.length
      this.getBabbles({filter: {user_id: this.userId}})
          .then(() => {
            if (scrollToEnd && babblesCount < this.babbles.length) {
              this.scrollDown = true
            }
          })
          .catch(e => console.log(e))
    },
    async trySendBubble({body, onSuccessCallback, onFinallyCallback}) {

      this.$refs.end_of_chat.scrollIntoView({behavior: "smooth", block: "center"})

      try {
        let attachmentHTML = await this.prepareMessageImages(this.files)
        this.files = [];

        let babble = await this.sendBabble({
          user_id: this.userId,
          body: [...attachmentHTML, marked.parse(body)].join('<br>')
        })

        this.addBabble({item: babble})

        if (onSuccessCallback) {
          onSuccessCallback()
        }

      } catch (e) {
        console.log(e)
      }

      this.scrollDown = true

      if (onFinallyCallback) {
        onFinallyCallback()
      }
    },
    tryDeleteBabble() {
      this.deleting = true
      this.deleteBabble(this.msgToDelete)
          .then(() => this.msgToDelete = null)
          .catch(this.$apiErrorHandler)
          .finally(() => this.deleting = false)
    },
    async tryNotifyAll({body, onSuccessCallback, onFinallyCallback}) {

      let attachmentHTML = await this.prepareMessageImages(this.notifyAllFiles)

      this.notifyAllFiles = []

      this.notifyUser({
        id: 'all',
        message: [...attachmentHTML, marked.parse(body)].join('<br>'),
        title: "Neue Nachricht der H+M Handelsgesellschaft",
        metadata: [this.user.id]
      })
          .then(() => {
            onSuccessCallback()
            this.msgToAll = false;
            this.notifyAllFiles = []
          })
          .catch(this.$apiErrorHandler)
          .finally(onFinallyCallback)
    },
    isOwnMessage(babble) {
      if (this.user.admin) {
        return (babble.mode === 'to')
      }

      return (babble.mode === 'from' && babble.user_id === this.user.id)
    },
    scrolledToEnd() {
      return this.$root.$el.clientHeight + this.$root.$el.scrollTop > (this.$root.$el.children[0].clientHeight)
    },
    userForId(id) {
      if (this.userId === 'admin') {
        return {username: "–"}
      }
      return this.users.find(el => el.id === parseInt(id)) || {};
    },
    async addToClipboard(imageUrl) {
      fetch(imageUrl)
          .then(response => response.blob())
          .then(image => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();

              reader.onload = () => {
                resolve(reader.result)
              }

              reader.onerror = reject;

              reader.readAsDataURL(image)
            })
          })
          .then(image => {
            return Clipboard.write({image})
          })
          .then(res => {
            console.log({res});
            Toastify({
              text: "Bild kopiert",
              duration: 3000,
              close: false,
              gravity: "bottom", // `top` or `bottom`
              position: "center", // `left`, `center` or `right`
              backgroundColor: "rgba(0,0,0,.7)",
              stopOnFocus: true, // Prevents dismissing of toast on hover
            }).showToast();
          })
          .catch(() => {
            Toastify({
              text: "Kopieren der Bildes fehlgeschlagen",
              duration: 3000,
              close: false,
              gravity: "bottom", // `top` or `bottom`
              position: "center", // `left`, `center` or `right`
              backgroundColor: "rgba(0,0,0,.7)",
              stopOnFocus: true, // Prevents dismissing of toast on hover
            }).showToast();
          })


    }
  }
}
</script>
