<template>
	<div class="account">
		<title-bar :with-logo="false" :title="title"></title-bar>

		<div v-if="tab === 'users'" class="hide-for-details">
			<div class="container">
				<div class="row">
					<div class="col-xs-12">
						<button type="button" class="btn bg-light-blue" @click="logout">Abmelden</button>
					</div>
					<div class="col-xs-12">
						<button type="button" class="btn btn-danger" @click="confirmAccountDeletion">Konto löschen</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
//Components
import TitleBar from '@/components/TitleBar'

import { mapActions, mapState } from 'vuex'

export default {
  components: { TitleBar },
  props: {
    tab: { type: String, default: 'users' },
  },
  watch: {
    tab (nv) {
      this.fetchDataForTab(nv)
    },
  },
  data () {
    return {
      title: 'Konto',
      message: undefined,
    }
  },
  computed: {
    ...mapState({
      apiBaseUrl: state => state.Api.apiBaseUrl,
      user: state => state.Api.Users.current,
      users: state => state.Api.Users.all,
      tasks: state => state.Api.Tasks.all,
    }),
  },
  methods: {
    ...mapActions({
      deleteAccount: 'Api/Users/deleteAccount',
      logout: 'Api/Users/logout',
    }),
    async confirmAccountDeletion() {
      if (confirm('Sind Sie sicher, dass Sie Ihr Konto wirklich löschen wollen?')) {
        await this.deleteAccount()

        alert('Ihr Konto wird jetzt gelöscht und Sie werden abgemeldet.')

        await this.logout()
      }
    }
  },
}
</script>
