<template>
  <form class="registartion-form" enctype="multipart/form-data"
        @submit.prevent="onSubmit">
    <div class="row">
      <div class="col-sm-12">
        <form-input
            label="Firma"
            name="company"
            required
            invalid-note="Geben Sie den Namen Ihrer Firma ein"
            v-model="fields.company"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <form-input
            label="Vorname"
            name="first_name"
            v-model="fields.first_name"
        />
      </div>
      <div class="col-sm-6">
        <form-input
            label="Nachname"
            name="last_name"
            required
            invalid-note="Geben Sie Ihren Nachnamen ein"
            v-model="fields.last_name"
        />
      </div>
    </div>
    <div class="row">

      <div class="col-sm-6">
        <form-input
            label="Geburtsdatum"
            name="date_of_birth"
            type="date"
            invalid-note="Geben Sie ein gültiges Datum ein"
            v-model="fields.date_of_birth"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <form-input
            label="Straße"
            required
            invalid-note="Geben Sie Ihre Straße ein"
            name="street"
            v-model="fields.street"
        />
      </div>
      <div class="col-sm-4">
        <form-input
            label="Hausnummer"
            name="house_number"
            invalid-note="Geben Sie Ihre Hausnummer ein"
            required
            v-model="fields.house_number"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <form-input
            label="PLZ"
            name="postal"
            required
            invalid-note="Geben Sie Ihre PLZ ein"
            v-model="fields.postal"
        />
      </div>
      <div class="col-sm-8">
        <form-input
            label="Ort"
            name="city"
            required
            invalid-note="Geben Sie einen Ort ein"
            v-model="fields.city"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <form-input
            label="Email"
            name="email"
            type="email"
            required
            invalid-note="Geben Sie Ihre E-Mail Adresse ein"
            v-model="fields.email"
        />
      </div>
      <div class="col-sm-6">
        <form-input
            label="Mobilnummer"
            name="phone"
            type="phone"
            invalid-note="Geben Sie Ihre Mobilnummer ein"
            required
            v-model="fields.phone"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <form-input
            label="Gewerbeanmeldung"
            name="business_registration"
            type="file"
            required
            invalid-note="Laden Sie Ihre Gewerbeanmeldung hoch"
            v-model="fields.business_registration"
        />
      </div>
      <div class="col-sm-12">
        <form-input
            label="Steuernummer"
            name="tax_number"
            required
            invalid-note="Geben Sie Ihre Steuernummer ein"
            v-model="fields.tax_number"
        />
      </div>
      <div class="col-xs-12">
        <button class="bg-blue xlarge-text spacer-bottom">Registrierung absenden</button>
      </div>

    </div>
  </form>
</template>

<script>
import {mapState} from "vuex";

import FormInput from "pixelstein-vue-app-package/src/components/Form/FormInput";

export default {
  name: "RegistrationForm",
  components: {FormInput},
  data() {
    return {
      fields: {
        company: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        street: '',
        house_number: '',
        postal: '',
        city: '',
        email: '',
        phone: '',
        business_registration: '',
        tax_number: '',
      }
    }
  },
  computed: {
    ...mapState({
      apiBaseUrl: state => state.Api.apiBaseUrl
    })
  },
  methods: {
    onSubmit(e) {

      let data = new FormData(e.target);
      fetch(this.apiBaseUrl + "/users/register.json", {
        method: 'POST',
        credentials: "include",
        body: data,

      })
          .then(resonse => resonse.json())
          .then(result => {
            if (result.error || result.code >= 400) {
              return Promise.reject(result.error)
            }
            this.fields = {};
            this.$toast.open({
              message: "Zugang erfolgreich angefragt",
              type: "success",
              position: 'top'
            })
            this.$emit('success')

          })
          .catch(error => {
            console.log(error)

            const fields = {
              company: "Firma",
              first_name: "Vorname",
              last_name: "Nachname",
              date_of_birth: "Geburtsdatum",
              street: "Straße",
              house_number: "Hausnummer",
              postal: "PLZ",
              city: "Stadt",
              email: "E-Mail Adresse",
              phone: "Mobilnummer",
              business_registration: "Gewerbeanmeldung",
              tax_number: "Steuernummer",
            }

            const errors = {
              _empty: "###FIELD### darf nicht leer sein",
              _required: "###FIELD### nicht vorhanden",
              _default: "Ungültiger Wert für ###FIELD###"
            }

            let errorStrings = [];

            for (let field in error.detail) {
              let localizedField = fields[field];
              Object.keys(error.detail[field]).forEach(key => {
                switch (key) {
                  case "_empty":
                    errorStrings.push(errors._empty.replaceAll(/###FIELD###/g, localizedField));
                    break;
                  case "_required":
                    errorStrings.push(errors._required.replaceAll(/###FIELD###/g, localizedField));
                    break;
                  default:
                    errorStrings.push(errors._default.replaceAll(/###FIELD###/g, localizedField));
                }
              })
            }

            errorStrings.forEach(errorString => this.$toast.open({
              message: errorString,
              type: 'error',
              position: 'top'
            }))
          })

    }
  }
}
</script>

<style scoped>

</style>
