<template>
  <div class="mobile-navigation">
    <slot/>
  </div>
</template>

<script>
import EmitHeight from "pixelstein-vue-app-package/src/mixins/emit-height"

export default {
  name: "MobileNavigation",
  mixins: [EmitHeight]
}
</script>

<style scoped>

</style>
