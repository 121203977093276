const UnauthenticatedExceptionType      = 'Authentication\\Authenticator\\UnauthenticatedException',
      InvalidInputExceptionType         = 'App\\Error\\InvalidInputException',
      AuthenticationFailedExceptionType = 'App\\Error\\AuthenticationFailedException',
      LimitExceededExceptionType        = 'App\\Error\\LimitExceededException';

const isExceptionType = (error, type) => type === error.type;

export default {
  created() {
    this.$apiErrorHandlerPlugin
        .addMiddleware(this.showErrorToast)
        .addMiddleware(this.authenticationRequiredHandler)
    ;
  },
  beforeDestroy() {
    this.$apiErrorHandlerPlugin
        .removeMiddleware(this.showErrorToast)
        .removeMiddleware(this.authenticationRequiredHandler)
    ;
  },
  methods: {
    showErrorToast(error) {
      if (isExceptionType(error, UnauthenticatedExceptionType)) {
        // do not display auth errors
        return;
      }
      const message = this.getLocalizedMessageForExceptionType(error.type)
          ?? error.detail
          ?? this.$t('api_errors.unknown');

      this.$toast.open({
        message,
        type: 'error',
        position: this.$config?.TOAST_POSITION ?? 'top',
      });
    },
    getLocalizedMessageForExceptionType(type) {
      switch (type) {
        case UnauthenticatedExceptionType:
          return this.$t('api_errors.UnauthenticatedExceptionType');
        case InvalidInputExceptionType:
          return this.$t('api_errors.InvalidInputExceptionType');
        case AuthenticationFailedExceptionType:
          return this.$t('api_errors.AuthenticationFailedExceptionType');
        case LimitExceededExceptionType:
          return this.$t('api_errors.LimitExceededExceptionType');
      }
    },
    authenticationRequiredHandler(error) {
      if (!isExceptionType(error, UnauthenticatedExceptionType)) {
        return;
      }

      this.$store.commit('Api/setAuthToken', null);
      this.$store.commit('Api/setAuthTokenData', null);

      // navigate to login
      if (!this.$router.currentRoute.path.match(
          /(enroll)|(reset_password)|(register)|(login)|(config)/gi)) {

        const loginRoute = process.env.LOGIN_ROUTE ?? '/login';
        const encodedBackRoute = this.$config?.BTOA_BACK_ROUTE_ENCODING
                                 ? btoa(this.$route.path)
                                 : encodeURIComponent(this.$route.path);

        this.$router
            .push([loginRoute, encodedBackRoute].join('/'))
            .catch(console.error);
      }

    },
  },
};
