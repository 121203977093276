<template>
  <div class="favorites view">
    <title-bar :with-logo="false" :title="title"/>

    <div class="container bg-white">
      <div class="row section-header">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-10">
              <p class="cart-info">
                Ihr Warenkorb enthält <strong>{{ cartCount }}</strong> Katalysatoren<br>
                Wert Ihres Warenkorbs: <strong>{{ cartValue }}</strong>
              </p>
            </div>
            <div class="col-xs-2">
              <button type="button" @click="clearAllModal = true">
                <i class="fas fa-trash-alt"></i>
              </button>
              <modal :visible="clearAllModal"
                     positive-button="Ja"
                     negative-button="Nein"

                     @positive-response="clearInventory"
                     @negative-response="clearAllModal = false"
              >
                Wollen Sie Ihren Warenkorb wirklich leeren?
              </modal>
            </div>
          </div>
          <hr>
        </div>
      </div>

      <div v-if="items.length > 0"
           ref="search_results"
           id="search_results"
           class="container-fluid bg-white spacer-bottom"
      >
        <div class="row">
          <template v-for="(item, idx) in visibleItems">

            <div
                :key="item.id"
                :class="{'last-item': idx === items.length-2}"
                class="col-12"
            >
              <kat-list-item
                  :item="item.converter"
                  :favorite="isInInventory(item.id)"
                  :image="imageData[buildImageUrl(item.converter.images)]"
                  :class="{'last-item': idx === visibleItems.length-2}"

                  @toggle-favorite="toggleFavorite(item)"
              >
                <template #default="{prices, formatter}">
                  <div>
                    <number-input
                        :value="amountForConverter(item.converter)"
                        @input="updateConverter(item.converter, $event)"
                        @remove="modalConverter = item.converter"
                        style="float: left"
                    />
                  </div>
                </template>

              </kat-list-item>
            </div>
          </template>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <template v-if="numberOfPages > 1">
              <template v-for="p in numberOfPages">
                <button
                    :key="p"
                    :class="currentPage === p ? 'bg-light-blue' : 'bg-light'"
                    style="width: auto;"
                    @click="setPage(p)">
                  <strong>{{ p }}</strong>
                </button>&nbsp;
              </template>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="container spacer-y">
        <div class="row spacer-y">
          <div class="col-xs-12 text-center spacer-y">
            <h3>
              Sie haben noch Katalysatoren in Ihrem Warenkorb!
            </h3>
            <i class="fas fa-basket-shopping spacer-top"></i>
            <p>
              Tippen Sie im Katalog bei einem Katalysator auf in den Warenkorb um diesen hinzuzufügen.
            </p>
          </div>
        </div>
      </div>

      <modal title="Aus dem Warenkorb entfernen?"
             :visible="!!modalConverter"
             negative-button="Abbrechen"
             @positive-response="removeFromInventory(modalConverter)"
             @negative-response="modalConverter = null"
      >
        <p v-html="$t('favorites.remove_confirmation', modalConverter)"></p>

      </modal>

      <pinch-zoom-overlay :image="zoomedImage" @hide="zoomedImage = ''"></pinch-zoom-overlay>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex'
import Vue from 'vue'

// Mixins
import inventoryHelper from '../mixins/inventory-helper.js'

// Components
import TitleBar from '../components/TitleBar'
import PinchZoomOverlay from '../components/PinchZoomOverlay'
import KatListItem from '../components/ProductListItem'
import NumberInput from '../components/NumberInput'
import Modal from '@/components/Modal'
import Bugsnag from '@bugsnag/js'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'Favorites',
  mixins: [inventoryHelper],
  components: {
    Modal,
    NumberInput,
    KatListItem,
    PinchZoomOverlay,
    TitleBar,
  },
  data () {
    return {
      zoomedImage: '',
      clearAllModal: false,
      lockSendButton: false,
      imageData: {},
      itemsPerPage: 10,
      currentPage: 1,
    }
  },
  computed: {
    ...mapState({
      user: state => state.Api.Users.current,
      inventories: state => state.Api.Inventory.all,
      apiBaseUrl: state => state.Api.apiBaseUrl,
    }),
    title () {
      return 'Warenkorb'
    },
    cartCount () {
      return this.items.map(({ amount }) => amount).reduce((a, b) => a + b, 0)
    },
    cartValue () {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
          .format(
              this.items
                  .map(({ amount, converter }) => amount * converter
                      .prices
                      .map(({ value }) => value).reduce((a, b) => a + b, 0),
                  )
                  .reduce((a, b) => a + b, 0),
          )
    },
    items () {
      if (!this.inventory) {
        return []
      }

      return this.inventory
          ?.inventory_converters
          ?.filter(({ converter }) => !!converter) ?? []
    },
    itemsCount () {
      return this.items.length
    },
    visibleItems () {
      const i = _cloneDeep(this.items)
      return i.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage)
    },
    numberOfPages () {
      return Math.ceil(this.itemsCount / this.itemsPerPage)
    },
  },
  methods: {
    setPage (p) {
      this.currentPage = p
      this.$root.$el.scrollTo(0, 0)
    },
    buildImageUrl (images) {
      let imageId = -1
      this.clearInventoryConverters()
      if (images && images.length > 0) {
        if (images[images.length - 1].id) {
          imageId = images[images.length - 1].id
        }
      }

      return [this.apiBaseUrl, '/images/get/', imageId, '.json'].join('')
    },
    loadImagesForPage () {
      this.items.forEach(el => {
        const url = this.buildImageUrl(el.converter.images)

        if (!this.imageData[url]) {
          fetch(url, {
            credentials: 'include',
          })
              .then(response => response.blob())
              .then(result => {
                Vue.set(this.imageData, url, URL.createObjectURL(result))
              })
              .catch(error => {
                this.$apiErrorHandler(error)
                Bugsnag.notify(new Error([error.error, url].join(' - ')))
              })
        }
      })
    },
    zoomImage (image) {
      this.zoomedImage = image
    },
  },
}
</script>
