<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="table-responsive">
        <table class="table table-hover" v-if="users.length > 0"
               style="margin: 15px 0; width: 100%">
          <tr class="table-head-row">
            <template v-for="userProp in Object.keys(users[0])">
              <th :key="'th_'+userProp" style="padding: 5px;border-right: 1px solid rgb(117, 117, 114);">{{
                  userProp
                }}
              </th>
            </template>
            <th>Login</th>
          </tr>
          <template v-for="user in sortedUsers">
            <tr @click="editUser = user" :key="user.username"
                style="border-bottom: 1px solid rgb(117, 117, 114); color: #797979" class="bg-white">
              <template v-for="userProp in Object.keys(user)">
                <td :key="'td_'+userProp" style="padding: 5px">{{ user[userProp] }}</td>
              </template>
              <td>
                <button type="button" class="bg-blue" @click="$emit('reset-device', user)">zurücksetzen</button>
              </td>
            </tr>
          </template>
        </table>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "UsersTable",
  props: {
    users: {type: Array, default: () => []}
  },
  computed: {
    sortedUsers() {
      return this.users.slice().sort((a, b) => a.username.localeCompare(b.username))
    },
  }
}
</script>

<style scoped>

</style>
