<template>
  <div class="search" :class="{'info-bg': convertersCount === 0}">
    <title-bar :with-logo="false" :title="title"/>
    <div class="bg-blue hide-for-details">
      <form @submit.prevent="search">
        <div id="search_container"
             class="container akkordeon-wrap akkordeon-collapsed medium-text">
          <div class="row">
            <div class="col-xs-12">
              <input type="text"
                     id="searchbox"
                     name="query"
                     placeholder="Bezeichnung z.B. KT1233 (alle O sind als Null einzugeben, keine Leerzeichen)"
                     autocorrect="off"
                     autocapitalize="off"
                     spellcheck="false"
                     v-model="query"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <button type="submit"
                      class="bg-light-blue search-button">
                Suchen
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
        ref="search_results"
        id="search_results"
        class="container bg-white">
      <div class="row section-header">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-6">
              Ergebnisse: {{ convertersCount }}
            </div>
            <div v-if="items.length > 0" class="col-xs-6 text-right">
              Seite {{ currentPage }} / {{ numberOfPages }}
            </div>
          </div>
          <hr>
        </div>
      </div>
      <template v-if="items.length > 0">
        <product-list-item
            v-for="(item) in items"
            :key="item.id"
            :item="item"
            :image="imageData[buildImageUrl(item.images)]"
            :message="user.message"
        >
          <button v-if="!isInInventory(item.id)"
                  type="button"
                  class="favorite_toggle"
                  :class="{active: isInInventory(item.id)}"
                  @click="toggleFavorite(item)"
          >
            In den Warenkorb
          </button>
          <div v-else>
            <number-input
                :value="amountForConverter(item)"
                @input="updateConverter(item, $event)"
                @remove="modalConverter = item"
                style="float: left"
            />
          </div>
        </product-list-item>
      </template>

      <div class="row">
        <div class="col-xs-12">
          <template v-if="numberOfPages > 1">
            <template v-for="p in numberOfPages">
              <button
                  :key="p"
                  :class="currentPage === p ? 'bg-light-blue' : 'bg-light'"
                  style="width: auto;"
                  @click="setPage(p)">
                <strong>{{ p }}</strong>
              </button>&nbsp;
            </template>
          </template>
        </div>
      </div>
    </div>


    <modal title="Aus dem Warenkorb entfernen?"
           :visible="!!modalConverter"
           negative-button="Abbrechen"
           @positive-response="removeFromInventory(modalConverter)"
           @negative-response="modalConverter = null"
    >
      <p v-html="$t('favorites.remove_confirmation', modalConverter)"></p>

    </modal>

    <modal :title="latestMessage.header"
           :body-text="latestMessage.body"
           :visible="latestMessage.visible"
           @positive-response="latestMessage.visible = false"
           @negative-response="latestMessage.visible = false"
    />

    <form v-if="items.length === 0"
          class="default-search-overlay"
          @submit.prevent="search"
    >
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-lg-8 col-lg-offset-2">

            <h2 style="line-height: 1.3em; margin-bottom: 1em; text-transform: none" class="text-center">
              Finden Sie Ihre Katalysatoren und tagesaktuelle Preise
            </h2>
            <p class="large-text">
              Welche Bezeichnung z.B. „GM28" oder
              Automarke suchen Sie? Geben Sie Ihre
              Suche einfach in das untenstehende Feld
              ein und Sie erhalten alle Katalysatoren-
              Modelle. die wir ankaufen und deren Wert
            </p>
            <input type="text"
                   name="query"
                   class="large-text"
                   placeholder="Automarke oder Bezeichnung"
                   autocorrect="off"
                   autocapitalize="off"
                   spellcheck="false"
                   style="color: #797979; padding: 10px 5px"
                   v-model="query">

            <p v-if="emptyResult" class="error">
              Zu Ihrer Suche wurde kein Katalysator gefunden.
            </p>

            <button type="submit"
                    class="bg-light-blue search-button large-text">
              Suchen
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Bugsnag from '@bugsnag/js'

import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

// Mixins
import InventoryHelper from '@/mixins/inventory-helper'

// Components
import TitleBar from '@/components/TitleBar'
import Modal from '@/components/Modal'
import ProductListItem from '@/components/ProductListItem'
import NumberInput from '@/components/NumberInput'
import Tracking from '../mixins/tracking.js'

export default {
  mixins: [InventoryHelper, Tracking],
  components: {
    NumberInput,
    ProductListItem,
    Modal,
    TitleBar,
  },
  data () {
    return {
      title: 'KATALOG',
      query: '',
      items: [],
      imageData: {},
      itemsPerPage: 50,
      currentPage: 1,
      emptyResult: false,
      latestMessage: {
        visible: false,
        header: '',
        body: '',
      },
    }
  },
  computed: {
    ...mapState({
      apiBaseUrl: state => state.Api.apiBaseUrl,
      user: state => state.Api.Users.current,
      converters: state => state.Api.Converters.all,
      convertersCount: state => state.Api.Converters.allCount,
    }),
    numberOfPages () {
      return Math.ceil(this.convertersCount / this.itemsPerPage)
    },
  },
  beforeMount () {
    this.getLatestMessage()
        .then(message => {
          // console.log({message})
          this.latestMessage = message
          this.$set(this.latestMessage, 'visible', true)
        })
        .catch(this.$apiErrorHandler)

    if (this.converters.length > 0) {
      this.setItems(this.converters)
    } else {
      this.setConvertersCount({ count: 0 })
    }

  },
  methods: {
    ...mapActions({
      allConverters: 'Api/Converters/all',
      getUserInfo: 'Api/Users/info',
      getLatestMessage: 'Api/Messages/latest',
    }),
    ...mapMutations({
      setUser: 'Api/Users/setCurrent',
      setConvertersCount: 'Api/Converters/setAllCount',
      resetConverters: 'Api/Converters/reset',
    }),
    search () {
      this.currentPage = 1
      this.fetchConverters()
    },
    setItems (converters) {
      this.items = converters.map(item => {
        return {
          ...item,
          showPrice: false,
        }
      })

      //load images of the first page
      this.loadImagesForPage()
    },
    fetchConverters () {
      this.resetConverters()
      this.allConverters({
        search: this.query,
        page: this.currentPage,
        limit: this.itemsPerPage,
      })
          .then(data => {
            this.emptyResult = data.length === 0

            this.setItems(data)

            window.localStorage.getItem('app_username')
            let id = this.$parent.user ? this.$parent.user.username : window.localStorage.getItem('app_username') ||
                'unknown'

            this.trackEvent('Search', 'USERNAME-' + id, this.query)

            this.loadImagesForPage()
          })
          .catch(this.$apiErrorHandler)
    },
    setPage (p) {
      this.currentPage = p
      this.fetchConverters()
      this.$root.$el.scrollTo(0, 0)
    },
    buildImageUrl (images) {
      let imageId = -1
      if (images && images.length > 0) {
        if (images[images.length - 1].id) {
          imageId = images[images.length - 1].id
        }
      }

      return [this.apiBaseUrl, '/images/get/', imageId, '.json'].join('')
    },
    loadImagesForPage () {
      this.items.forEach(el => {
        const url = this.buildImageUrl(el.images)

        if (!this.imageData[url]) {
          fetch(url, {
            credentials: 'include',
          })
              .then(response => response.blob())
              .then(result => {
                Vue.set(this.imageData, url, URL.createObjectURL(result))
              })
              .catch(error => {
                this.$apiErrorHandler(error)
                Bugsnag.notify(new Error([error.error, url].join(' - ')))
              })
        }
      })
    },
  },
}
</script>
