<template>
  <div class="number-input">
    <button type="button" class="clear-btn" @click="$emit('remove')">
      <i class="fas fa-trash-alt"></i>
    </button>
    <button @click="decrease">-</button>
    <input type="number"
           step=".5"
           min="0"
           :value="value"
           @input="onInput"
    />
    <button @click="increase">+</button>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  props: {
    value: {type: Number, default: 0}
  },
  methods: {
    increase() {
      this.emitValue(this.value + 1)
    },
    decrease() {
      this.emitValue(Math.max(this.value - 1, 0))
    },
    onInput(e) {
      if (e.target.value.length === 0) {
        return
      }
      let newValue = parseFloat(e.target.value);
      this.emitValue(newValue)
    },
    emitValue(newValue) {
      this.$emit('input', parseFloat(newValue));
    }
  }
}
</script>

