<template>
  <div class="admin">

    <title-bar :with-logo="false" :title="title"></title-bar>

    <div class="bg-blue hide-for-details">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 spacer-top">
            <ul class="nav nav-tabs" role="tablist">
              <li role="presentation" :class="{active: tab === 'users'}">
                <router-link to="/admin/users">Benutzer</router-link>
              </li>
              <li role="presentation">
                <a @click.prevent="onDownload()">Login Log&nbsp;&nbsp;<span
                    class="glyphicon glyphicon-save"></span></a>
              </li>
              <li role="presentation" :class="{active: tab === 'converters'}">
                <router-link to="/admin/converters">Katalysatoren</router-link>
              </li>
              <li role="presentation" :class="{active: tab === 'messages'}">
                <router-link to="/admin/messages">Willkommens Nachricht</router-link>
              </li>
              <li role="presentation" :class="{active: tab === 'babble'}">
                <router-link to="/admin/babble/chat">Chat</router-link>
              </li>
              <li role="presentation" :class="{active: tab === 'tasks'}">
                <router-link to="/admin/tasks">Tasks</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="tab === 'users'" class="hide-for-details">
      <title-bar :with-logo="false" :title="'Benutzer'"></title-bar>
      <div class="container">
        <file-upload :api-action="apiBaseUrl+'/users/update.json'"
                     accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <users-table :users="users" @reset-device="resetUserDevice"></users-table>
      </div>

    </div>
    <div v-else-if="tab === 'converters'" class=" hide-for-details">
      <title-bar :with-logo="false" :title="'KAT Daten'"></title-bar>
      <div class="container">
        <file-upload accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                     :api-action="apiBaseUrl+'/converters/update.json'"></file-upload>
      </div>
      <title-bar :with-logo="false" :title="'KAT Bilder'"></title-bar>

      <div class="container">
        <file-upload :api-action="apiBaseUrl+'/images/update.json'"></file-upload>
      </div>

    </div>
    <div v-else-if="tab === 'messages'" class=" hide-for-details">
      <title-bar :with-logo="false" :title="'Messages'"></title-bar>
      <div class="container">
        <messages :message="message" @input="message = {...message, ...$event}" @save="onSaveMessage"/>
      </div>

    </div>
    <div v-else-if="tab === 'tasks'" class="hide-for-details">
      <title-bar :with-logo="false" :title="'Laufende Tasks'"></title-bar>
      <div class="container">
        <tasks :tasks="tasks" @update-data="fetchDataForTab('tasks')"/>
      </div>

    </div>
    <div v-else-if="tab === 'babble'" class="hide-for-details">
      <router-view/>
    </div>
  </div>
</template>
<script>
//Components
import TitleBar from '@/components/TitleBar'
import FileUpload from '@/components/FileUpload'
import UsersTable from '@/components/UsersTable'
import Messages from '@/components/Messages'
import Tasks from '@/components/Tasks'
import { saveAs } from 'file-saver'

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: { Tasks, UsersTable, TitleBar, FileUpload, Messages },
  props: {
    tab: { type: String, default: 'users' },
  },
  watch: {
    tab (nv) {
      this.fetchDataForTab(nv)
    },
  },
  data () {
    return {
      title: 'ADMINISTRATOR',
      message: undefined,
    }
  },
  computed: {
    ...mapState({
      apiBaseUrl: state => state.Api.apiBaseUrl,
      user: state => state.Api.Users.current,
      users: state => state.Api.Users.all,
      tasks: state => state.Api.Tasks.all,
    }),
  },
  beforeMount () {
    let goBack = () => {
      window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push('/')
    }

    if (!this.user.admin) {
      goBack()
    }

    this.fetchDataForTab(this.tab)
  },
  methods: {
    ...mapMutations({
      removeTask: 'Api/Tasks/remove',
    }),
    ...mapActions({
      getUsers: 'Api/Users/all',
      resetLoginToken: 'Api/Users/reset-login-token',
      getTasks: 'Api/Tasks/all',
      getLatestMessage: 'Api/Messages/latest',
      addMessage: 'Api/Messages/add',
      logout: 'Api/Users/logout',
      getLog: 'Api/Users/getLog',
    }),
    resetUserDevice (user) {
      this.resetLoginToken({
        id: user.id,
      })
          // this.apiRequest(['/users/reset-login-token/', user.id, '.json'].join(''))
          .then(() => alert('Login erfolgreich zurückgesetzt'))
          .catch(this.$apiErrorHandler)
    },
    fetchDataForTab (tab) {
      switch (tab) {
        case 'users':
          this.getUsers()
              .catch(this.$apiErrorHandler)
          break
        case 'messages':
          this.getLatestMessage()
              .then(message => this.message = message)
              .catch(this.$apiErrorHandler)
          break
        case 'tasks':
          this.getTasks()
              .then(tasks => {
                this.tasks
                    .filter(t => !tasks.find(nt => nt.id === t.id))
                    .forEach(t => this.removeTask(t))
              })
              .catch(this.$apiErrorHandler)
          break
      }
    },
    onSaveMessage () {
      this.addMessage(this.message)
          .catch(this.$apiErrorHandler)
    },
    async onDownload () {
      const log = await this.getLog({
        id: 'combined',
        $forceResponseType: 'blob'
      })

      saveAs(log, 'combined.csv')
    },
  },
}
</script>
