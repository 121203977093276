<template>
  <div class="privacy-bar bg-light-blue ">
    <div class="container bottom-nav-padding">
      <div class="row">
        <div class="col-12 text-center">
          <a href="http://hm-handel.com/impressum.html" target="_blank">Impressum &amp; Datenschutz</a>
        </div>
        <div class="col-12">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacyBar"
}
</script>
