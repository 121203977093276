'use strict'

import { mapActions } from 'vuex'

function getResolvedHref (router, path) {
  const resolved = router.resolve(path)
  
  return resolved.href
}

export default {
  methods: {
    ...mapActions({
      trackUserAction: 'Api/Users/track',
    }),
    buildTrackingParameters () {
      const width = parseInt(window.screen.width, 10)
      const height = parseInt(window.screen.height, 10)
      
      const res = width + 'x' + height
      
      const now = new Date()
      
      const url = getResolvedHref(
        this.$router,
        this.$router.currentRoute,
      )
      
      return {
        url: window.location.origin + url,
        res,
        h: now.getHours(),
        m: now.getMinutes(),
        s: now.getSeconds(),
        fla: false,
        java: false,
        qt: false,
        realp: false,
        pdf: !!window.navigator?.mimeTypes?.['application/pdf'],
        wma: false,
        ag: false,
        cookie: navigator.cookieEnabled,
      }
    },
    async trackEvent (category, action, name = false, value = false) {
      await this.trackUserAction({
        ca: true,
        e_c: category,
        e_a: action,
        e_n: name,
        e_v: value,
        ...this.buildTrackingParameters(),
      })
    },
    async trackPageView (router, to, from) {
      const title = to.meta.title || getResolvedHref(router, to)
      const referrerUrl = from
        ? window.location.origin + getResolvedHref(router, from)
        : undefined
      
      await this.trackUserAction({
        action_name: title,
        urlref: referrerUrl,
        ...this.buildTrackingParameters(),
      })
    },
    startPageViewTracking () {
      this.$router.afterEach(async (to, from) => {
        await this.trackPageView(this.$router, to, from)
      })
    },
  },
}
