<template>
	<div id="title-bar" class="container ">
		<div class="row">
			<div v-if="withLogo" class="col-xs-12 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4 align-center">
				<img id="logo" class="img-responsive" src="@/assets/img/logo.png" alt="LOGO">
			</div>
			<div class="col-xs-12 align-center">
				<h2>{{title}}</h2>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "TitleBar",
		props: {
			withLogo: Boolean,
			title: String
		}
	}
</script>
