<template>
  <div v-if="loaded" class="home">
    <title-bar :with-logo="true"></title-bar>

    <form @submit.prevent="tryLogin">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <input type="text" name="username" placeholder="Benutzername"
                   class="xlarge-text" autocorrect="off" autocapitalize="off"
                   spellcheck="false"
                   v-model="username">
          </div>
          <div class="col-xs-12">
            <input type="password" name="pin" placeholder="Passwort"
                   class="xlarge-text" autocorrect="off" autocapitalize="off"
                   spellcheck="false"
                   v-model="password">
          </div>
          <div class="col-xs-12">
            <div v-if="loginOptions.platform !== 'web'" class="checkbox" style="display: flex; align-items: center">
              <input id="store_credentials"
                     style="position: static; width: 1.5em; height: 1.5em; margin: 0; visibility: visible"
                     type="checkbox"
                     v-model="storeCredentials"
              >
              <label for="store_credentials" style="height: auto; margin: 0; font-size: 1.3em">
                Angemeldet bleiben
              </label>
            </div>

            <button type="submit" name="submit"
                    :disabled="lock"
                    class="bg-light-blue xlarge-text spacer-bottom">
              <template v-if="!lock">ANMELDEN</template>
              <img v-else src="@/assets/img/loading-gray.svg" style="max-height: 1em" alt="loading">
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="container">
      <hr style="margin-bottom: 0 !important;">
      <ps-collapse>
        <template v-slot:header="{active}">
          <div class="xlarge-text spacer-bottom spacer-top"
               style="display: flex; justify-content: space-between; align-items: center;">
            Registrieren <span :class="{active}" class="glyphicon glyphicon-menu-down"></span>
          </div>
        </template>
        <template v-slot:content="{ toggleActive}">
          <div style="padding-bottom: 100px;padding-top: 1em">
            <registration-form @success="toggleActive()"/>
          </div>
        </template>
      </ps-collapse>
      <hr>
    </div>

    <privacy-bar>
      <div v-if="appInfo.version" class="text-center small text-white spacer-top">
        Version {{ appInfo.version }}
      </div>
    </privacy-bar>

    <div id="loader_overlay">
      <img src="@/assets/img/akr-loading.svg" alt="working..">
    </div>
  </div>
</template>

<script>
import {Preferences} from '@capacitor/preferences';

import {mapState, mapActions, mapMutations} from "vuex";


import LoginOptions from "../mixins/capacitor-login-options"

import TitleBar from "@/components/TitleBar";
import PrivacyBar from "@/components/privacyBar";
import RegistrationForm from "@/components/RegistrationForm";
import PsCollapse from "pixelstein-vue-app-package/src/components/Accordion/PsCollapse";

export const STORE_CREDENTIALS_KEY = "store_credentials"
export const USERNAME_KEY = "username"
export const PASSWORD_KEY = "password"

export default {
  name: 'Login',
  components: {PsCollapse, RegistrationForm, PrivacyBar, TitleBar},
  mixins: [LoginOptions],
  props: {
    // user: {type: Object}
    successRedirect: {type: String, default: () => encodeURIComponent("/search")}
  },
  data() {
    return {
      username: '',
      password: '',
      storeCredentials: false,
      loaded: false,
      lock: false
    };
  },
  computed: {
    ...mapState({
      pushToken: state => state.PushNotifications.token
    })
  },
  mounted() {
    (async () => {
      const {value: storeCredentials} = await Preferences.get({key: STORE_CREDENTIALS_KEY});
      console.log(STORE_CREDENTIALS_KEY, {storeCredentials})
      this.storeCredentials = JSON.parse(storeCredentials || "false");

      const {value: username} = await Preferences.get({key: USERNAME_KEY});
      console.log(USERNAME_KEY, {username})
      this.username = username || "";

      const {value: password} = await Preferences.get({key: PASSWORD_KEY});
      console.log(PASSWORD_KEY, {password})
      this.password = password || "";

      try {

        const user = await this.info()

        this.setUser({item: user});
        this.$router.push('/search').catch(console.log)
      } catch (e) {
        console.log(e)
        if (username && password && this.storeCredentials) {
          this.tryLogin()
        }
      }

      this.loaded = true
    })()

  },
  methods: {
    ...mapActions({
      login: "Api/Users/login",
      logout: "Api/Users/logout",
      info: "Api/Users/info"
    }),
    ...mapMutations({
      setUser: "Api/Users/setCurrent",
      setToken: "Api/setAuthToken"
    }),
    showRegistration() {
      window.open('https://hm-handel.com/registrierung.html', '_blank')
    },
    async tryLogin() {
      this.lock = true;

      try {
        await this.logout()
      } catch (e) {
        console.log(e)
      }

      try {
        const user = await this.login({
          username: this.username,
          password: this.password,
          push_token: this.pushToken,

          ...this.loginOptions,
          $uploadProgressCallback: (e) => console.log('upload =>', e),
          $downloadProgressCallback: (e) => console.log('download =>', e)
        })

        this.setUser({item: user});

        this.setToken(this.loginOptions.device_identifier);

        await Preferences.set({key: STORE_CREDENTIALS_KEY, value: JSON.stringify(this.storeCredentials)})

        await Preferences.set({key: USERNAME_KEY, value: this.username})

        if (this.storeCredentials) {
          await Preferences.set({key: PASSWORD_KEY, value: this.password})
        }

        this.$parent.userInfoPoll(); // start polling in App.vue, nested routes not supported

        this.$router.push(decodeURIComponent(this.successRedirect)).catch(console.log)

      } catch (error) {
        this.$apiErrorHandler(error)
      }

      this.lock = false;

    }
  },
}
</script>
