<template>
  <div class="row">
    <div class="col-xs-12">
      <table class="table table-hover"
             v-if="tasks.length > 0"
             style="margin: 15px 0; width: 100%">
        <tr class="table-head-row">
          <template v-for="header in Object.values(cols)">
            <th :key="header" style="padding: 5px;border-right: 1px solid #a0c225;">{{ header }}</th>
          </template>
        </tr>
        <template v-for="task in tasks">
          <tr :key="task.id"
              style="border-bottom: 1px solid #a0c225; color: #797979" class="bg-white">
            <template v-for="prop in Object.keys(cols)">
              <td :key="prop" style="padding: 5px">{{ task[prop] }}</td>
            </template>
          </tr>
        </template>
      </table>

    </div>
  </div>
</template>

<script>

export default {
  name: "Tasks",
  props: {
    tasks: {type: Array, default: () => []}
  },
  data() {
    return {
      intervalId: -1,
      cols: {
        id: 'ID',
        type: 'Typ',
        file: 'Datei',
        status: 'Status'
      },
    }
  },
  mounted() {
    this.intervalId = window.setInterval(() => this.$emit('update-data'), 1000);
  },
  beforeDestroy() {
    window.clearInterval(this.intervalId)
  }
}
</script>

<style scoped>

</style>
