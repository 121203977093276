import {v1} from 'uuid';

import {App}     from '@capacitor/app';
import {Device}  from '@capacitor/device';
import {Preferences} from '@capacitor/preferences';

export default {
  data() {
    return {
      deviceInfo: {},
      appInfo: {},
      deviceUuid: null,
      debug: (process.env.NODE_ENV === 'development'),
    };
  },
  computed: {
    loginOptions() {
      return {
        app_id: this.appInfo.id,
        platform: this.deviceInfo.platform,
        name: this.deviceInfo.name || this.deviceInfo.osVersion,
        device_identifier: this.deviceUuid,
        debug: this.debug,
      };
    },
  },
  created() {
    Device.getInfo()
        .then(info => this.deviceInfo = info)
        .catch((e) => console.log('Device Info error', e));

    App.getInfo()
        .then(info => this.appInfo = info)
        .catch(() => this.appInfo = {id: window.location.hostname});

    (async () => {
      const storageKey = 'device_identifier';
      let {value: uuid} = await Preferences.get({key: storageKey});
      if (uuid === null) {
        uuid = v1();

        await Preferences.set({
          key: storageKey,
          value: uuid,
        });
      }

      this.deviceUuid = uuid;
    })();

  },
};
