<template>
  <div class="row">
    <div class="col-xs-12">
      <input class="form-control spacer-bottom"
             :value="message.header"
             @input="$emit('input', {header: $event.target.value})"/>
      <textarea class="form-control"
                :value="message.body"
                @input="$emit('input', {body: $event.target.value})"
      ></textarea>
    </div>
    <div class="col-xs-12 col-sm-6">
      <button type="button"
              class="bg-light-blue search-button"
              @click="preview = !preview">
        Vorschau
      </button>
      <modal
          :title="message.header"
          :bodyText="message.body"
          :visible="preview"

          @positive-response="preview = !preview"
          @negative-response="preview = !preview"
      ></modal>
    </div>
    <div class="col-xs-12 col-sm-6 spacer-bottom">
      <button type="button"
              class="bg-light-blue search-button"
              @click="$emit('save')">
        Speichern
      </button>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "Messages",
  components: {Modal},
  props: {
    message: {type: Object, default: () => ({header: '', body: ''})}
  },
  data() {
    return {
      preview: false
    }
  },
}
</script>

<style scoped>

</style>
