import {App} from '@capacitor/app';

import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'
import Search from './views/Search'
import Admin from './views/Admin'
import Chat from "./views/Chat";
import Favorites from "./views/Favorites";
import Account from './views/Account.vue'

Vue.use(Router)

const router = new Router({
	mode: "hash",
	routes: [
		{
			path: "/",
			redirect: "login"
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
		},
		{
			path: '/login/:successRedirect',
			name: 'Login',
			component: Login,
			props: true
		},
		{
			path: '/search',
			name: 'Search',
			component: Search
		},
		{
			path: '/favorites',
			name: 'Favorites',
			component: Favorites
		},
		{
			path: '/chat',
			name: 'Chat',
			component: Chat,
		},
		{
			path: '/chat/:userId',
			name: 'Chat',
			component: Chat,
			props: true
		},
		{
			path: '/admin',
			redirect: '/admin/users',
			meta: {
				analyticsIgnore: true
			}
		},
		{
			path: '/admin/:tab',
			name: 'Admin',
			component: Admin,
			meta: {
				analyticsIgnore: true
			},
			props: true,
			children: [
				{
					path: "chat",
					component: Chat
				},
				{
					path: 'chat/:userId',
					name: 'Chat',
					component: Chat,
					props: true
				},
			]
		},
    {
      path: '/account',
      name: 'Account',
      component: Account,
      props: true
    },
	]
})

App.addListener('appUrlOpen', function (event) {
	const url = new URL(event.url);
	const appPath = router.mode === 'hash' ? url.hash.split('#').pop() : url.pathname
	
	// We only push to the route if there is a appPath present
	if (appPath) {
		router
			.push({
				path: appPath,
			})
			.catch(console.log);
	}
});


export default router
