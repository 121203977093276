<template>
  <form class="chat-control" @submit.prevent="onSubmit">
    <div class="chat-control-inner-wrap">
      <div class="chat-attachments-wrap" v-if="allowUploads">
        <multi-file-upload listen-paste-events="html"
                           :max-file-count="100"
                           :key="imgk"
                           @input="$emit('files-changed', $event)"/>
      </div>
      <div class="chat-input-wrap">
        <form-input v-model="message" :disabled="disabled || lock"/>
      </div>
      <div class="chat-submit-wrap">
        <button :disabled="disabled || lock" class="btn btn-primary bg-blue">
          <i class="fa-solid fa-paper-plane" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {v1} from 'uuid'

import FormInput from "pixelstein-vue-app-package/src/components/Form/FormInput";
import EmitHeight from "pixelstein-vue-app-package/src/mixins/emit-height";
import MultiFileUpload from "@/components/MultiFileUpload";

export default {
  name: "ChatControl",
  mixins: [EmitHeight],
  components: {
    MultiFileUpload,
    FormInput
  },
  props: {
    disabled: {type: Boolean, default: false},
    allowUploads: {type: Boolean, default: false}
  },
  data() {
    return {
      imgk: v1(),
      message: '',
      lock: false,
    }
  },
  methods: {
    onSubmit() {
      const onSuccessCallback = () => {
        this.message = ''
        this.imgk = v1();
      }
      const onFinallyCallback = () => this.lock = false

      this.lock = true;
      this.$emit('submit',
          {
            body: this.message,
            onSuccessCallback,
            onFinallyCallback
          })
    }
  }
}
</script>

<style scoped>

</style>
