<template>
  <div id="menu_bar_wrap" class="bg-blue top-nav-padding">
    <div id="menu_bar" class="container bg-blue">
      <div class="row">
        <div class="col-xs-9 col-md-8">
          <h1 style="white-space: nowrap">H+M Handelsgesellschaft mbH</h1>
        </div>
        <div id="logout" class="col-xs-3 col-md-4 align-right">
          <slot/>&nbsp;&nbsp;
          <a href="#"
             @click.prevent="$emit('logout')">
            <b class="logout-label">Abmelden</b>
            <i class="fa-solid fa-right-from-bracket"></i>
          </a>
        </div>
      </div>
    </div>
    <div style="position: absolute; top: -100px; height: 100px; left: 0; right: 0; background: #fff; z-index: 999;"></div>
  </div>
</template>

<script>

export default {
  name: "Navigation",
}
</script>

<style scoped>

</style>
