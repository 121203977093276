import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Api from "@/store/Api";
import PushNotifications from "@/store/PushNotifications";

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {Api, PushNotifications}
})
