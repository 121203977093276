<template>
  <transition name="slide">
    <div id="popup" v-if="visible" @click="emitResponse(false)">
      <div class="container" @click.stop>
        <div class="row bg-light-blue">
          <div class="col-xs-12">
            <a href="#" class="close-popup"
               v-if="negativeButton"
               @click="emitResponse(false)"><img alt="X" src="@/assets/img/schliessen_button_weiss.png"></a>
            <span v-if="title" class="popup-title">{{ title }}</span>
            <div class="popup-inner">
              <p v-if="bodyText" class="popup-text">{{ bodyText }}</p>
              <slot/>
            </div>
          </div>
          <div class="col-xs-4 col-xs-offset-4">
            <button v-if="negativeButton" :disabled="lock" class="negative-btn bg-blue" @click="emitResponse(false)">
              {{ negativeButton }}
            </button>
          </div>
          <div class="col-xs-4">
            <button class="positive-btn bg-blue" :disabled="lock" @click="emitResponse(true)">{{
                positiveButton
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
    bodyText: String,
    visible: Boolean,
    lock: {type: Boolean, default: false},
    positiveButton: {
      type: String,
      default: "OK"
    },
    negativeButton: {
      type: String,
      default: ""
    },
  },
  methods: {
    emitResponse(positiv) {
      this.$emit(positiv ? 'positive-response' : 'negative-response')
    }
  }
}
</script>

<style scoped>

</style>
