<template>
  <div class="product-list-item">
    <div class="row flex-row">
      <div class="col-sm-5 text-center">
        <loader-image :src="image" :alt="item.name"
                      class="img-responsive"/>

      </div>
      <div class="col-sm-7">
        <h3>{{ item.name }}</h3>
        <p class="price">
          Beschreibung: {{ item.description }}
        </p>
        <template v-if="!hidePrice">
          <template v-if="displayPrice">
            <p class="price"
               v-for="(price, priceIndex) in item.prices"
               :key="item.name + item.description  + priceIndex"
            >
              Preis: {{ formatPrice(price.value) }}
            </p>
          </template>
          <template v-else>
            <button type="button"
                    class="bg-light-blue spacer-bottom"
                    style="display: inline-block !important; width: auto !important;"
                    @click="showPrice(item)"
            >Preis anzeigen
            </button>
          </template>
        </template>
        <p class="price" v-if="message">
          {{ message }}
        </p>
        <slot :prices="item.prices" :formatter="formatPrice"/>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 spacer-top">
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderImage from '@/components/LoaderImage';
import Tracking from '../mixins/tracking.js'
import { mapState } from 'vuex'

export default {
  name: 'ProductListItem',
  mixins: [Tracking],
  components: {LoaderImage},
  props: {
    item: {type: Object, default: null},
    image: {type: String, default: ''},
    message: {type: String, default: null},
    hidePrice: {type: Boolean, default: false},
  },
  data() {
    return {
      displayPrice: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.Api.Users.current,
    })
  },
  methods: {
    async showPrice(item) {
      try {
        let id = this.user
            ? this.user.username
            : (window.localStorage.getItem('app_username') || 'unknown');

        await this.trackEvent('Price', 'USERNAME-' + id, item.description)

        this.displayPrice = true;
      } catch (err) {
        console.log('tracking failed, no price for you')
      }
    },
    formatPrice(price) {
      const priceNumber = parseFloat(price);
      if (!isNaN(priceNumber)) {
        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(priceNumber);
      }
      return price;
    },
  },
};
</script>
