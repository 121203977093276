<template>
  <div id="app" class="nav-spacing" :style="'padding-bottom:'+(bottomPadding+10)+'px'">
    <div>
      <navigation v-if="!$route.path.match('login') && user" @logout="tryLogout">
        <router-link to="/search" class="visible-lg-inline-block" style="margin: 0 15px">
          <b>Katalog</b>
        </router-link>
        <router-link to="/favorites" class="visible-lg-inline-block" style="margin: 0 15px">
          <b>Warenkorb</b>
        </router-link>
        <router-link to="/chat" class="visible-lg-inline-block" style="margin: 0 15px">
          <b>Chat</b><span v-if="badgeNumber > 0" class="badge" style="margin-top: -1em; font-size: .8em">{{
            badgeNumber
          }}</span>
        </router-link>
        <router-link to="/account" class="visible-lg-inline-block" style="margin: 0 15px">
          <b>Konto</b>
        </router-link>
        <router-link v-if="user.admin" to="/admin/users" class="visible-lg-inline-block" style="margin: 0 15px">
          <b>Admin</b>
        </router-link>
      </navigation>

      <router-view v-if="$route.path.match('login') || user"/>

      <mobile-navigation
          v-if="!$route.path.match('login') && user"
          class="hidden-lg"
          @resize="bottomPadding=$event"
      >
        <router-link to="/search">
          <i class="fa-solid fa-magnifying-glass fa-2x" aria-hidden="true"></i><br>
          Katalog
        </router-link>
        <router-link to="/favorites">
          <i class="fa-solid fa-basket-shopping fa-2x" aria-hidden="true"></i><br>
          Warenkorb
        </router-link>
        <router-link to="/chat">
          <i class="fa-solid fa-message fa-2x" aria-hidden="true"></i><br>
          Chat <span v-if="badgeNumber > 0" class="badge">{{ badgeNumber }}</span>
        </router-link>
        <router-link to="/account">
          <i class="fa-solid fa-user fa-2x" aria-hidden="true"></i><br>
          Konto
        </router-link>
        <router-link v-if="user.admin" to="/admin">
          <i class="fa-solid fa-lock fa-2x" aria-hidden="true"></i><br>
          Admin
        </router-link>
      </mobile-navigation>
    </div>
    <modal title="Error"
           :body-text="lastError"
           :visible="errorVisibility"
           @positive-response="errorVisibility = false"></modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import ApiAppMixin from 'pixelstein-vue-app-package/src/mixins/api-app-mixin'

import Modal from '@/components/Modal'
import MobileNavigation from '@/components/MobileNavigation'
import Navigation from '@/components/Navigation'
import { PASSWORD_KEY, STORE_CREDENTIALS_KEY } from '@/views/Login'
import { Preferences } from '@capacitor/preferences'

import { PushNotifications } from '@capacitor/push-notifications'
import AppErrorHandling from '@/mixins/app-error-handling'
import { Capacitor } from '@capacitor/core'
import Tracking from './mixins/tracking.js'

export default {
  name: 'App',
  components: { MobileNavigation, Modal, Navigation },
  mixins: [
    AppErrorHandling,
    ApiAppMixin,
    Tracking,
  ],
  data () {
    return {
      lastError: '',
      errorVisibility: false,
      preventAuthTokenCheck: true,
      bottomPadding: 0,
    }
  },
  computed: {
    ...mapState({
      user: state => state.Api.Users.current,
      users: state => state.Api.Users.all,
      badgeNumber: state => state.PushNotifications.badgeNumber,
    }),
  },
  created () {
    this.userInfoPoll()
    document.body.classList.add('top-nav-padding')

    this.registerPush()

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'PushNotifications/setToken') {
        //TODO: device token update via Users/modify
      }

      // Update Badge No.
      if (mutation.type === 'Api/Users/setCurrent' && mutation.payload.item) {
        let badgeNo = 0
        if (this.user.admin) {
          badgeNo = this.users.reduce(
              ((previousValue, currentValue) => previousValue + currentValue.unread_admin_count), 0)
        } else {
          badgeNo = this.user.unread_user_count
        }
        this.updateBadge({ badgeNo })
      }
    })

    this.$router.afterEach(to => {
      if (!this.user && !to.path.match('login')) {
        this.$router.push('login/' + encodeURIComponent(to.path))
      }
    })

    if (Capacitor.getPlatform() !== 'web') {
      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', JSON.stringify(notification), notification.actionId, notification.inputValue)

        if (notification.actionId === 'tap') {
          const sender = notification.notification.data.sender_id ?? notification.notification.data.sender

          if (sender) {
            this.$router.push(
                '/chat/' + sender)
          } else {
            this.$router.push('/chat')
          }
        }

      }).catch(console.log)
    }

    this.startPageViewTracking()
  },
  beforeDestroy () {
    if (Capacitor.getPlatform() !== 'web') {
      PushNotifications.removeAllListeners().catch(console.log)
    }

    if (this.userInfoPollingTimeout) {
      window.clearTimeout(this.userInfoPollingTimeout)
    }

    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe()
    }

  },
  methods: {
    ...mapActions({
      registerPush: 'PushNotifications/register',
      getUserInfo: 'Api/Users/info',
      getUsers: 'Api/Users/all',
      modifyUser: 'Api/Users/modify',
      updateBadge: 'PushNotifications/updateBadge',
    }),
    ...mapMutations({
      setUser: 'Api/Users/setCurrent',
      setToken: 'Api/setAuthToken',
    }),
    async tryLogout () {
      try {
        await this.logout()
      } catch (e) {
        this.$apiErrorHandler(e)
      }
      this.setUser({ item: null })
      this.setToken(null)

      await Preferences.remove({ key: STORE_CREDENTIALS_KEY })
      await Preferences.remove({ key: PASSWORD_KEY })

      this.$router.push('/').catch(console.log)
    },
    userInfoPoll () {
      // poll users / userInfo to display chat badge
      if (this.userInfoPollingTimeout) {
        window.clearTimeout(this.userInfoPollingTimeout)
      }

      const errorHandler = (error) => {
        switch (error.code) {
          case 401:

            if (!this.$router.currentRoute.path.match(/(enroll)|(reset_password)|(register)|(login)|(config)/gi)) {
              const encodedBackRoute = this.$config.BTOA_BACK_ROUTE_ENCODING
                  ? btoa(this.$router.currentRoute.path)
                  : encodeURIComponent(this.$router.currentRoute.path)

              this.$router.push([(process.env.LOGIN_ROUTE || '/login'), encodedBackRoute].join('/'))
            }

            return
        }
        this.$apiErrorHandler(error)
      }

      if (this.user?.admin) {
        // if current user has admin permissions fetch all users
        this.getUsers().then(() => {
          this.userInfoPollingTimeout = window.setTimeout(this.userInfoPoll, 15000)
        }).catch(errorHandler)
      } else {
        this.getUserInfo().then(user => {
          this.setUser({ item: user })
          this.userInfoPollingTimeout = window.setTimeout(this.userInfoPoll, 15000)
        }).catch(errorHandler)
      }

    },
  },
}
</script>

<style lang="scss">
@import "styles/main";
</style>
